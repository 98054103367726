export const imports = {
  'src/home.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-home" */ 'src/home.mdx'
    ),
  'src/bot/auth.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-bot-auth" */ 'src/bot/auth.mdx'
    ),
  'src/bot/lineworks.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-bot-lineworks" */ 'src/bot/lineworks.mdx'
    ),
  'src/bot/works.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-bot-works" */ 'src/bot/works.mdx'
    ),
  'src/bot/개요.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-bot" */ 'src/bot/개요.mdx'
    ),
}
